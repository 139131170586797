<script setup lang="ts">
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
const isMobileSearchOpen = ref(false);

provide('isMobileSearchOpen', isMobileSearchOpen);

const { $eventClient } = useNuxtApp();

watch(isMobileSearchOpen, (newVal) => {
	if (newVal) {
		document.body.style.overflow = 'hidden';
	} else {
		document.body.style.overflow = 'auto';
	}
});

onBeforeUnmount(() => {
	document.body.style.overflow = 'auto';
});

provide('isMobileSearchOpen', isMobileSearchOpen);
const handleClick = () => {
	$eventClient.sendEvent('mobile-search-clicked', {
		url: window.location.href,
	});
};
</script>

<template>
	<div
		class="py-md px-xl bg-gray-100 rounded-medium flex gap-md [&_svg]:size-5xl w-full"
		@click="handleClick"
	>
		<PhMagnifyingGlass />
		<span class="text-left text-sm text-gray-500 line-clamp-1">
			Search for products, brands and categories
		</span>
	</div>
</template>
