<script setup lang="ts">
import {
	Button,
	Dialog,
	DialogContent,
	DialogTrigger,
	Input,
} from '@laam/ui/base';
import SearchNodes from './SearchNodes.vue';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { useDebounceFn } from '@vueuse/core';
import type { searchNode } from '~/types/search';
import { useSearchSuggestions } from '~/data/search-auto-suggestions';
import { useTrendingSearches } from '~/data/trending-searches';
import { useRecentSearches } from '~/data/recent-searches';

const { deviceType } = useDeviceType();
const { $eventClient } = useNuxtApp();
const route = useRoute();

const isMobile = computed(() => deviceType.value === 'mobile');
const pathName = computed(() => route.path);
const nodeFromQuery = computed(() => {
	if (route.query?.node_id) {
		return (
			SEARCH_NODES.find(
				(node) => node.id === parseInt(route.query.node_id as string),
			) ?? SEARCH_NODES[0]
		);
	}

	return SEARCH_NODES[0];
});
provide('nodeFromQuery', nodeFromQuery);

const filterValue = ref<string>((route?.query?.search as string) ?? ''); // Create a reactive data to store the search query
const isSearchNodesOpen = ref(false);
provide('isSearchNodesOpen', isSearchNodesOpen);

const selectedNode = ref<searchNode>(nodeFromQuery.value!);
provide('selectedNode', selectedNode);

const debouncedSearchQuery = ref<string>(filterValue.value ?? '');
provide('debouncedSearchQuery', debouncedSearchQuery);
provide('selectedNode', selectedNode);

const enableSearchQuery = ref<boolean>(
	pathName.value !== '/search' ? true : false, //setting input focus to true so that on hydrating it is set to true
);

const { data, isLoading: suggestionsLoading } = useSearchSuggestions(
	debouncedSearchQuery,
	selectedNode,
	enableSearchQuery,
);

const { data: trendingData } = useTrendingSearches(
	debouncedSearchQuery,
	enableSearchQuery,
);

const { data: recentSearches } = useRecentSearches(
	debouncedSearchQuery,
	enableSearchQuery,
);

const searchSuggestionsDebounce = useDebounceFn(async () => {
	debouncedSearchQuery.value = filterValue.value!;
}, 500);

const handleInputChange = () => {
	searchSuggestionsDebounce();
};

const handleFocusIn = () => {
	enableSearchQuery.value = true;
};

const handleFocusOut = () => {
	enableSearchQuery.value = false;
	const inputField = document.getElementById('inputField');
	if (inputField) {
		inputField.blur();
	}
};

const filters = computed(() => {
	const filters = route.fullPath.split('?')[1] || '';
	if (!filters) return '';
	const params = new URLSearchParams(filters);
	params.delete('search');
	params.delete('node_id');

	return params.toString();
});
provide('filters', filters);
provide('filterValue', filterValue);

const searchQuery = async () => {
	if (filterValue.value === '') {
		if (pathName.value === '/search') {
			navigateTo('/'); // Navigate to homepage if current path is search otherwise stay on current route
		}
	} else {
		FBConversion.apiCall({
			event_name: 'search',
			custom_data: {
				search_string: filterValue.value,
			},
		});
		$eventClient.sendEvent('product-search', {
			query: filterValue.value,
			nodeId: nodeFromQuery.value!.id,
		});

		const searchPath = getSearchQueryPath(
			filters.value,
			nodeFromQuery.value!.id,
			selectedNode.value.id,
			filterValue.value,
			pathName.value,
		); //getting search query url path with applied filters, node id and search query

		await navigateTo(searchPath);
		handleFocusOut(); //always focus out on route change
	}
};

const handleEnterClick = () => {
	searchQuery();
};

const clearSearchQuery = () => {
	filterValue.value = '';
	debouncedSearchQuery.value = '';
	isSearchNodesOpen.value = false;
	const inputField = document.getElementById('inputField');
	if (inputField) {
		inputField.focus();
	}
};

const handleSearchCancel = () => {
	if (pathName.value === '/search') {
		handleFocusOut();
		navigateTo('/');
	} else {
		isModalOpen.value = false;
	}
};

provide('handleFocusOut', handleFocusOut);

const isModalOpen = ref(false);
</script>

<template>
	<Dialog v-model:open="isModalOpen">
		<DialogTrigger class="w-full">
			<slot />
		</DialogTrigger>
		<DialogContent
			:show-close="false"
			class="flex flex-col left-0 right-0 translate-x-0 !top-0 bottom-0 !translate-y-0 !p-3xl gap-md"
		>
			<div class="flex h-fit">
				<SearchNodes />

				<Input
					id="inputField"
					v-model="filterValue"
					class="text-sm text-gray-500 z-[40] rounded-l-none flex items-center bg-gray-100 max-h-9xl rounded-medium gap-md border-gray-200 grow border-l-0"
					name="laam-search"
					placeholder="Search fashion"
					:auto-complete="'off'"
					@input="handleInputChange"
					@focus="handleFocusIn"
					@keydown.enter="handleEnterClick"
				>
					<template #leftIcon>
						<div>
							<PhMagnifyingGlass size="20" />
						</div>
					</template>

					<template #rightIcon>
						<Button
							v-if="enableSearchQuery && !!filterValue"
							class="laam-search-clear p-sm border-none bg-transparent hover:bg-transparent"
							:variant="isMobile ? 'secondary' : 'tertiary'"
							:size="'lg'"
							@click.stop="clearSearchQuery"
						>
							<span><PhX class="" /> </span>
						</Button>
					</template>
				</Input>
				<Button
					v-if="isMobile"
					class="px-md py-sm laam-search-cancel border-none bg-transparent hover:bg-transparent"
					:variant="isMobile ? 'secondary' : 'tertiary'"
					:size="isMobile ? 'xl' : 'lg'"
					s
					@click="handleSearchCancel"
				>
					<span>Cancel</span>
				</Button>
			</div>
			<div class="relative">
				<ClientOnly>
					<SearchAutoSuggestions
						:suggestions-loading="suggestionsLoading"
						:suggestions="data!"
						:trending-searches="trendingData!"
						:recent-searches="recentSearches!"
					/>
				</ClientOnly>
			</div>
		</DialogContent>
	</Dialog>
</template>
