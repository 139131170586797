<script setup lang="ts">
import { LaamIcon } from '@laam/ui/icons';
import CartButton from './CartButton.vue';
import type { BannerReasons } from './TopBanner.vue';
import DeliveryOptions from './delivery-options/DeliveryOptions.vue';
import SearchDialog from './search/SearchDialog.vue';
import SearchButton from './SearchButton.vue';
const route = useRoute();

const allowedRoutes = ['identifier', 'new-arrivals'];

const isVisible = computed(() => allowedRoutes.includes(route.name as string));

const { searchButtonV2Experiment } = useStatsigExperiment();

const topBannerReason = inject('topBannerReason') as Ref<BannerReasons>;
const visible = inject('headerVisible') as Ref<boolean>;
const showNov11Sale = inject('showNov11Sale') as boolean;
const deliveryOptionsExp = inject('deliveryOptionsExp') as Ref<boolean>;
</script>
<template>
	<div
		v-if="isVisible"
		:data-top-banner="!!topBannerReason"
		:data-visible="visible"
		class="sticky left-0 right-0 z-10 transition-all border-b border-gray-200 laam--header-mobile slide-in-from-top lg:hidden"
	>
		<div class="flex bg-white navigation--mobile gap-8xl pr-md pt-md">
			<div class="flex justify-between w-full">
				<NuxtImg
					v-if="showNov11Sale"
					src="/nov_11_sale.gif"
					class="h-[36px] ml-xl"
				/>
				<LaamIcon v-else variant="laam_logo_complete" class="h-[36px] ml-xl" />
				<div class="flex items-center gap-md">
					<DeliveryOptions v-if="deliveryOptionsExp" />
					<SearchButton v-if="!searchButtonV2Experiment?.is_active" />
					<CartButton />
				</div>
			</div>
		</div>
		<div
			v-if="searchButtonV2Experiment?.is_active"
			class="bg-white px-3xl pb-md"
		>
			<SearchDialog>
				<SearchButtonV2 />
			</SearchDialog>
		</div>
	</div>
</template>

<style scoped>
.laam--header-mobile[data-visible='true'][data-top-banner='true'] {
	top: var(--laam-shipping-banner-height);
}
.laam--header-mobile[data-visible='true'][data-top-banner='false'] {
	top: 0;
}
.laam--header-mobile[data-visible='false'] {
	top: calc(
		0 - var(--laam-topbar-height-mobile - var(--laam-shipping-banner-height))
	) !important;
}
</style>
